<template>
  <modal-form
    v-slot="{ properties, errors, entity }"
    type="editScale"
    :options="{
      properties: {
        name: '',
        ipAddress: '',
        store: null,
      },
      postUri: '/scales',
      label: $t('scale.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input v-model="properties.ipAddress" :label="$t('general.field.ipAddress')" :error="errors.ipAddress" required />
    <multiselect
      v-if="entity['@id']"
      v-model="properties.store"
      :options="async (q) => loadStoreOptions(q, properties.store)"
      :loading="loadingStoreOptions"
      :error="errors.store"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
  </modal-form>
</template>
<script>
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import useLoadOptions from '@/hooks/api/loadOptions';

export default {
  name: 'EditScaleModal',
  components: {
    FancyInput,
    ModalForm,
    Multiselect,
  },
  setup() {
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    return {
      loadStoreOptions,
      loadingStoreOptions,
    };
  },
};
</script>
