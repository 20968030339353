<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('customerScanner.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editCustomerScanner', entity: {
          deviceName: '',
          supportedIdentifierTypes: [],
          store: `/stores/${$route.params.storeId}`,
        } })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editCustomerScanner', entity: normalizeEntity($event) })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteCustomerScanner', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    >
      <template #cell(supportedIdentifierTypes)="data">
        {{ data.entity.supportedIdentifierTypes.map((type) => $t(`customerIdentifier.type.${type}`)).join(', ') }}
      </template>
    </entity-table>

    <edit-customer-scanner-modal @update="loadEntities" />
    <delete-modal type="deleteCustomerScanner" entity-translation-key="customerScanner" label="deviceName" @update="loadEntities" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditRegisterModal from '@/components/entities/register/EditRegisterModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditCustomerScannerModal from '@/components/entities/customer_scanner/EditCustomerScannerModal.vue';

export default {
  name: 'CustomerScannerIndexPage',
  components: {
    DeleteModal,
    EditCustomerScannerModal,
    EditRegisterModal,
    EntityTable,
  },
  setup() {
    const route = useRoute();
    const { customerScanner: fields } = useEntityFields();

    const normalizeEntity = (entity) => ({
      ...entity,
      supportedIdentifierTypes: entity.supportedIdentifierTypes.map((i) => Number(i)),
      store: entity?.store?.['@id'],
    });

    const {
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
    } = useLoadEntities('/customer_scanners', {
      sort: 'deviceName',
      defaultParams: {
        store: route.params.storeId,
      },
    });

    return {
      fields,
      entities,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      normalizeEntity,
    };
  },
};
</script>
