<template>
  <modal-form
    v-slot="{ properties, errors, entity }"
    type="editCustomerScanner"
    :options="{
      properties: {
        deviceName: '',
        supportedIdentifierTypes: [],
        scannerType: 10,
        store: null,
      },
      postUri: '/customer_scanners',
      label: $t('customerScanner.label')
    }"
  >
    <fancy-input v-model="properties.deviceName" :label="$t('general.field.name')" :error="errors.name" required />
    <simple-dropdown v-model="properties.scannerType" :options="scannerTypes" :label="$t('customerScanner.scannerType.label')" :required="true" />
    <multiselect
      v-model="properties.supportedIdentifierTypes"
      mode="tags"
      :options="customerIdentifierOptions"
      :form-label="$t('customerScanner.supportedTypes.label')"
      :filter-results="false"
      :required="true"
      searchable
    />
    <multiselect
      v-if="entity['@id']"
      v-model="properties.store"
      :options="async (q) => loadStoreOptions(q, properties.store)"
      :loading="loadingStoreOptions"
      :error="errors.store"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
  </modal-form>
</template>
<script>
import { useI18n } from 'vue-i18n';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import useLoadOptions from '@/hooks/api/loadOptions';

export default {
  name: 'EditCustomerScannerModal',
  components: {
    FancyInput,
    ModalForm,
    SimpleDropdown,
    Multiselect,
  },
  setup() {
    const { t } = useI18n();
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    const customerIdentifierOptions = [
      { name: t('customerIdentifier.type.10'), '@id': 10 },
      { name: t('customerIdentifier.type.20'), '@id': 20 },
    ];

    const scannerTypes = [
      { key: '10', label: t('customerScanner.scannerType.10'), value: 10 },
    ];

    return {
      loadStoreOptions,
      loadingStoreOptions,
      customerIdentifierOptions,
      scannerTypes,
    };
  },
};
</script>
